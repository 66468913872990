import React, { useEffect, useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCog,
  faHome,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/logo.png';
import {useHistory} from 'react-router-dom';
import {deleteCookies} from '../utils/functions';
import cookie from '../services/cookie/index';
import Modal from "../components/Modal/index.jsx";
import UpdateUser from "../views/UpdateUser/index.jsx";
import UpdatePassword from "../views/UpdatePassword/index.jsx";
import api from "../services/client/api";

export default function Main(props) {
	const {Page} = props;

	let history = useHistory();
	const [collapsed, setCollapsed] = useState(true);
	const toggleNavbar = () => setCollapsed(!collapsed);
	let role = cookie().get("role");
	const [userUpdateID, setUserUpdateID] = useState(0);
	const [modalUpdate, setModalUpdate] = useState(false);
	const [modalPassUpdate, setModalPassUpdate] = useState(false);

	useEffect(() => {
		openUpdatePassword();
	});

	const openUpdateUser = () => {
		let id = cookie().get("id");
		setUserUpdateID(id);
		setModalUpdate(true);
	};

	const openUpdatePassword = async () => {
		let id = cookie().get("id");

		const token = cookie().get("token");
		const response = await api().user().getByID(token, id);

		if (response.status === 401) {
			await deleteCookies();
			return history.push({ pathname: "/login" });
		}

		if (response.status === 200) {
			if (!response.output.data.first_login) {
				setUserUpdateID(id);
				setModalPassUpdate(true);
				return;
			}else{
				setModalPassUpdate(false);
				return;
			}
		}
	};

  return (
		<div>
			<Navbar
				color='faded'
				expand='md'
				light
				style={{ borderBottom: "1px solid #ddd", background: "#FFF" }}>
				<NavbarBrand
					onClick={() => history.push({ pathname: "/" })}
					className='mr-auto'>
					<img
						src={logo}
						alt='Logo CEDAP'
						style={{ height: "32px" }}
					/>
				</NavbarBrand>

				<NavbarToggler onClick={toggleNavbar} className='mr-2' />

				<Collapse isOpen={!collapsed} navbar>
					<Nav className='mr-auto' navbar>
						<NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								onClick={() => {
									setCollapsed(true);
									history.push({ pathname: "/" });
								}}>
								<FontAwesomeIcon icon={faHome} /> Home
							</NavLink>
						</NavItem>

						{role === "paciente" && (
							<NavItem>
								<NavLink
									style={{ cursor: "pointer" }}
									onClick={() => openUpdateUser()}>
									<FontAwesomeIcon icon={faUserCog} /> Perfil
								</NavLink>
							</NavItem>
						)}

						<NavItem>
							<NavLink
								style={{ cursor: "pointer" }}
								onClick={() => {
									setCollapsed(true);
									deleteCookies();
									history.push({ pathname: "/login" });
								}}>
								<FontAwesomeIcon icon={faSignOutAlt} /> Sair
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>

			<Modal
				title='Editar Usuário'
				size='lg'
				modal={modalUpdate}
				setModal={setModalUpdate}
				Content={() => <UpdateUser userID={userUpdateID} />}
			/>

			<Modal
				title='Editar Senha'
				size='lg'
				modal={modalPassUpdate}
				setModal={setModalPassUpdate}
				Content={() => <UpdatePassword userID={userUpdateID} />}
			/>

			<Page />
		</div>
  );

}

import React, {useState} from 'react';
import { Alert, Spinner, FormGroup, Form } from 'reactstrap';
import { ButtonLogin, InputLogin } from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import {useHistory} from 'react-router-dom';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';

function Login() {
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');
  let [inProgress, setInProgress] = useState(false);
  let [error, setError] = useState(false);
  let [msgError, setMsgError] = useState('erro');
  let history = useHistory();

  const login = async (e) => {
    e.preventDefault();
    setInProgress(true);

    const response = await api()
		.user()
		.login({ username: username, password: password });
    
    if (response.status === 200) 
    {
      
      let nameSplited = response.output.data.user.name.split(" ")
      await cookie().set("firstName", nameSplited[0]);
      await cookie().set("fullname", response.output.data.user.name);
      await cookie().set("id", response.output.data.user.id);
      await cookie().set("role", response.output.data.user.role);
      await cookie().set("code", response.output.data.user.code);
      await cookie().set("token", response.output.data.token);
      
      return history.push({ pathname:'/' });
    } 

    if( ! response.message ) {
      response.message = "Usuário ou senha incorretos.";
    }

    setMsgError(response.message)
    setError(true)
    setInProgress(false);

  }

  return (
    <>
      
      {(error)&& <Alert color="danger">{msgError}</Alert>}

      <Form onSubmit={login}>
          <FormGroup>
            <InputLogin 
              type="text"
              placeholder="Digite seu login"
              value={username} 
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <InputLogin 
              type="password"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>

          { (inProgress) ?
              <Spinner color="primary" />
            :
            <ButtonLogin color="primary" type="submit">
              Logar <FontAwesomeIcon icon={faArrowRight} />
            </ButtonLogin>
          }
      </Form>

    </>
  );
}

export default Login;

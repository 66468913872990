import React, {useEffect, useState} from 'react';
import { 
  Alert,
  Spinner,
  FormGroup,
  Form,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col,
} from 'reactstrap';
import { FormWrapperForm, InputView } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faKey,
  faWrench,
  faIdCard,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import api from '../../services/client/api';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie/index';
import {deleteCookies} from '../../utils/functions';

export default function UpdatePassword({userID}) {

  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [askcode, setAskcode] = useState(false);
  const [first, setFirst] = useState(true);
  const [fields, setFields] = useState({role: 'admin', banned: false, code: ""});
  const [msgError, setMsgError] = useState('erro');
  const [msgSuccess, setMsgSuccess] = useState('sucesso');
  const history = useHistory();
  
  useEffect(() => first&&getUser() )

  const getUser = async () => {
    
    setFirst(false)

    const token = await cookie().get("token")
    const response = await api().user().getByID(token, userID);
    
    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
        
    if (response.status === 200) 
    {
      setFields(response.output.data);
      return 
    } 

  }

  const update = async (e) => {
    e.preventDefault();
    setInProgress(true);

    fields.id = parseInt(userID);
    fields.first_login = 1;

    const token = cookie().get("token")
    const response = await api().user().update(token, fields);
	console.log( response );

    if( response.status !== 200 ) {
      	setMsgError(response.message);
		setError(true);
    }
    
    if (response.status === 401) {
    	await deleteCookies();
    	return history.push({ pathname:"/login"});
    } 
    
    if (response.status === 201) {
    	return history.go(0);
    }

	if (response.output.data.status === 'sendCode') {
		setAskcode(true);
		setSuccess(false);
		setError(false);
	} else if (response.output.data.status) {
		setMsgSuccess("Senha alterada com sucesso!");
		setSuccess(true);
		setError(false);
	} else {
		setMsgError(response.output.data.message);
		setError(true);
		setSuccess(false);
	}

	setInProgress(false);
  }

  return (
		<FormWrapperForm>
			{error && <Alert color='danger'>{msgError}</Alert>}
			{success && <Alert color='success'>{msgSuccess}</Alert>}

			<Form onSubmit={update}>
				<Row form>
					<Col md={12}>
						<p>
							{ askcode ? 'Foi enviado um email com o código de confirmação, insira-o abaixo e altere sua senha:' : 'Para sua segurança confirme seus dados e altere a sua senha antes de continuar:' }
						</p>
					</Col>
					{ !askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faUser} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.fullname}
									placeholder='Nome Completo'
									type='text'
									name='fullname'
									onChange={(e) =>
										setFields({
											...fields,
											fullname: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
					{ !askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faIdCard} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.cpf}
									placeholder='CPF'
									type='text'
									name='cpf'
									onChange={(e) =>
										setFields({
											...fields,
											cpf: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
					{ !askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faIdCard} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.rg}
									placeholder='RG'
									type='text'
									name='rg'
									onChange={(e) =>
										setFields({
											...fields,
											rg: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
					{ !askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faUser} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.mother}
									placeholder='Nome da mãe'
									type='text'
									name='mother'
									onChange={(e) =>
										setFields({
											...fields,
											mother: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
					{ !askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faEnvelope} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.cemail}
									placeholder='E-mail'
									type='text'
									name='email'
									onChange={(e) =>
										setFields({
											...fields,
											cemail: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
					{ !askcode ?
					<Col md={6}></Col>
					: ''}
					{ askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faKey} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.access_code}
									placeholder='Código enviado para o Seu e-mail'
									type='text'
									name='mailcode'
									onChange={(e) =>
										setFields({
											...fields,
											access_code: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
					{ askcode ?
					<Col md={6}>
						<FormGroup>
							<InputGroup>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<FontAwesomeIcon icon={faKey} />
									</InputGroupText>
								</InputGroupAddon>

								<InputView
									required
									value={fields.password}
									placeholder='Nova Senha'
									type='password'
									name='password'
									onChange={(e) =>
										setFields({
											...fields,
											password: e.target.value,
										})
									}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					: ''}
				</Row>

				{inProgress ? (
					<Spinner color='primary' />
				) : (
					<Button color='primary' type='submit'>
						<FontAwesomeIcon icon={faWrench} /> Editar
					</Button>
				)}
			</Form>
		</FormWrapperForm>
  );
}
import React, { useState } from "react";
import { Table, Button, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import { formatDateNew } from "../../utils/functions";
import api from "../../services/client/api";
import cookie from "../../services/cookie/index";
import { useHistory } from "react-router-dom";
import { deleteCookies } from "../../utils/functions";

export default function ListReports({ reports }) {
	const [dowloading, setDownloading] = useState({ numberReport: null });
	let history = useHistory();

	const download = async (numberReport, typeReport) => {
		setDownloading({ numberReport: numberReport });

		const token = await cookie().get("token");
		let response = [];
		if( typeReport === 1 ) {
			response = await api()
				.report()
				.downloadColpocitology(token, numberReport);
		}else if( typeReport === 2 ) {
			response = await api()
				.report()
				.downloadHistology(token, numberReport);
		}


		if (response.status === 401) {
			await deleteCookies();
			return history.push({ pathname: "/login" });
		}

		if (response.status === 200) {
			window.open(response.output.data.reportPdf);
			setDownloading({ numberReport: null });

			return;
		}
		setDownloading({ numberReport: null });
	};

	if (reports.length < 1) {
		return <div></div>;
	}

	return (
		<div style={{ marginTop: "50px" }}>
			<h5>
				<FontAwesomeIcon icon={faSearch} /> {reports.length} resultados
				encontrados
			</h5>
			<Table striped bordered responsive style={{ marginTop: "50px" }}>
				<thead>
					<tr>
						<th>#Exame</th>
						<th>Funções</th>
						<th>Paciente</th>
						<th>Data de Nascimento</th>
						<th>Instituição de origem</th>
						<th>Data da solicitação</th>
					</tr>
				</thead>
				<tbody>
					{reports.map((report) => (
						<tr key={report.Nro_Exame}>
							<th scope='row'>{report.Nro_Exame}</th>
							<td>
								{report.Quitado ? (
									<Button
										disabled={
											dowloading.numberReport ===
											report.Nro_Exame
										}
										color='primary'
										onClick={() =>
											download(
												report.Nro_Exame,
												report.Tipo_Laudo
											)
										}>
										{dowloading.numberReport ===
										report.Nro_Exame ? (
											<>
												<Spinner />{" "}
												Downloading
											</>
										) : (
											<>
												<FontAwesomeIcon
													icon={faDownload}
												/>{" "}
												Download
											</>
										)}
									</Button>
								) : (
									"Documento não quitado."
								)}
							</td>
							<td>{report.Nome_Paciente}</td>
							<td>
								{ report.Data_Nascimento ?
								formatDateNew(report.Data_Nascimento.date)
								: ""
								}
							</td>
							<td>{report.Instituicao_Origem}</td>
							<td>
								{formatDateNew(report.Data_Solicitacao.date)}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
}

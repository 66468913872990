import ApiConnection from './connection';

const getAll = async (token, reportData) =>
	ApiConnection.post(`reports`, reportData, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

const downloadHistology = async (token, numberReport) =>
	ApiConnection.get(`reports/histologia/${numberReport}`, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

const downloadColpocitology = async (token, numberReport) =>
	ApiConnection.get(`reports/colpocitologia/${numberReport}`, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

export default function report() {
  return {
		getAll,
		downloadColpocitology,
		downloadHistology,
  };
}
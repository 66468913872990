import React, {useEffect, useState} from 'react';
import { 
  Alert,
  Spinner,
  FormGroup,
  Form,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col,
} from 'reactstrap';
import { FormWrapperForm, InputView } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faEnvelope,
  faKey,
  faWrench
} from '@fortawesome/free-solid-svg-icons'
import api from '../../services/client/api';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie/index';
import {deleteCookies} from '../../utils/functions';

export default function UpdateUser({userID}) {

  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [first, setFirst] = useState(true);
  const [fields, setFields] = useState({role: 'admin', banned: false, code: ""});
  const [msgError, setMsgError] = useState('erro');
  const history = useHistory();
  
  useEffect(() => first&&getUser() )

  const getUser = async () => {
    
    setFirst(false)

    const token = await cookie().get("token")
    const response = await api().user().getByID(token, userID);
    
    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
        
    if (response.status === 200) 
    {
      setFields(response.output.data);
      return 
    } 

  }

  const create = async (e) => {
    e.preventDefault();
    setInProgress(true);

    fields.id = userID;
    fields.first_login = 1;

    const token = cookie().get("token")
    const response = await api().user().update(token, fields);
    
    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status === 201) 
    {
      return history.go(0);
    } 

    setMsgError(response.message)
    setError(true)
    setInProgress(false);

  }

  return (
    <FormWrapperForm>
      
      {(error)&& <Alert color="danger">{msgError}</Alert>}

      <Form onSubmit={create}>

        <Row form>

          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUser} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView required value={fields.name} placeholder="Nome" name="name" onChange={(e) => setFields({...fields, name:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView required value={fields.email} placeholder="E-mail" type="email" name="email" onChange={(e) => setFields({...fields, email:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>

        </Row>
        <Row form>

          <Col md={6}>
            <FormGroup>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUser} />
                  </InputGroupText>
                </InputGroupAddon>
              
                <InputView required value={fields.user_name} placeholder="Login" name="user_name" onChange={(e) => setFields({...fields, user_name:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faKey} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView required value={fields.password} placeholder="Senha" type="password" name="password" onChange={(e) => setFields({...fields, password:e.target.value})}/>
              
              </InputGroup>
            </FormGroup>
          </Col>

        </Row>        
        
          { (inProgress) ?
              <Spinner color="primary" />
            :
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faWrench} /> Editar
            </Button>
          }
      </Form>

    </FormWrapperForm>
  );
}
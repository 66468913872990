import React, {useEffect, useState} from 'react';
import ListReports from './list.jsx'
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import {deleteCookies} from '../../utils/functions';
import {useHistory} from 'react-router-dom';
import { faMicroscope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Reports() {

  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (isLoading) {
      getReports()
    }
    
  },[])

  const getDateToday = () => {
    let date = new Date();
    date.getFullMonth = function () {
      let month = this.getMonth() + 1;
      return month > 9 ? month : '0'+month;
    }
  
    date.getFullDay = function () {
      let day = this.getDate();
      return day > 9 ? day : '0'+day;
    }
  
    return date.getFullYear() + "-" + date.getFullMonth() + "-" + date.getFullDay()
  }

  const getReports = async () => {

    const reportData = {
		id: cookie().get("id"),
		role: cookie().get("role"),
		code: cookie().get("code"),
		patient: "",
		date_start: "1900-01-01 00:00:00",
		date_end: getDateToday() + " 00:00:00",
	};

    const token = cookie().get("token")
    const response = await api().report().getAll(token, reportData);

    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status === 200) 
    {
      setReports(response.output.data)
      setIsLoading(false)
      
      return
    } 
    setIsLoading(false)
  }

  return (
    <div>
      <h1><FontAwesomeIcon icon={faMicroscope} /> Meus Exames</h1>
      {isLoading?
        <p>Carregando...</p>
      :
        <ListReports reports={reports}/>
      }
    </div>
  );
}